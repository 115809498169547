import React from 'react'
import PostListElement from './PostListElement';
import './styles.scss';

interface PostListProps {
    posts: any
}

const PostList: React.FC<PostListProps> = ({ posts }) => {
    return (
        <div className="bg-gray-100">
            <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
                <div className="absolute inset-0">
                    <div className="bg-white h-1/3"></div>
                </div>

                <div className="relative container mx-auto">
                    <div className="text-center">
                        <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
                            Lee nuestro blog
                    </h2>
                        {/* <p className="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsa libero labore natus atque, ducimus sed.
                        </p> */}
                    </div>
                    <div className="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
                        {posts && posts.map((post, index) => {
                            return (
                                <PostListElement
                                    slug={post.slug.current}
                                    title={post.title}
                                    mainImage={post.mainImage}
                                    description={post._rawExcerpt}
                                />
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PostList