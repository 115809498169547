import React from "react"
import Layout from "../../components/Layout/Layout"
import { graphql } from "gatsby"
import Seo from "../../components/SEO/Seo"
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../../services/helpers"
import Navbar from "../../components/Navbar"
import Hero from "../../components/Hero"
import PostList from "../../components/PostList"

const HomeTemplate = ({ data }) => {
  const seoContent = {
    title: "En nombre de Dios",
    description:
      "Blog cristiano con contenidos referentes a la palabra de Dios. Jesus es el Mesías. Las imagenes de Dios y jesucristo que te recordaran lo mas preciado en la vida, imagenes cristianas, imagenes de jesus, desmotivaciones y mensajes de dios",
    image:
      "https://cdn.sanity.io/images/nvvatjjc/production/4ed50c65531eedc2495e7989b60274237da6c9f1-725x948.jpg?w=500&fit=crop",
    url: `https://www.ennombrededios.com`,
    article: false,
  }

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : []

  return (
    <Layout>
      <Seo content={seoContent} />

      <Navbar />
      <Hero
        title="Bienvenido a En Nombre de Dios"
        image={
          "https://res.cloudinary.com/omochism/image/upload/f_auto,q_75/v1613839617/blog/lqhk4n7i3uqusqlrim2y.jpg"
        }
      />

      <PostList posts={postNodes} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query IndexPageQuery {
    posts: allSanityPost(
      limit: 40
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          _rawExcerpt(resolveReferences: { maxDepth: 5 })

          mainImage {
            asset {
              url
            }
            caption
            alt
          }
        }
      }
    }
  }
`

export default HomeTemplate
