import React from "react"
import './styles.scss';

interface PostProps {
    title: string
    image: string
    animate: boolean
}

const Hero: React.FC<PostProps> = ({ title, image, animate }) => {
    return (
        <div className="hero h-full w-full relative flex items-center justify-center">
            {/* <div className="hero-background-wrapper absolute h-full w-full z-0"> */}
            <div className="hero-background absolute h-full w-full z-0" style={{
                backgroundImage: `url(${image})`
            }} />
            {/* </div> */}
            <div className="conteiner text-center z-10">
                <h1 className="m-0 text-3xl font-extrabold text-white sm:text-4xl md:text-5xl">
                    {title}
                </h1>
            </div>

        </div>
    )
}
export default Hero
