import React from 'react'
import { Link } from "gatsby";
const BlockContent = require('@sanity/block-content-to-react')

interface PostListElementProps {
    slug: string,
    title: string,
    mainImage: any,
    description: any
}

const PostListElement: React.FC<PostListElementProps> = ({ slug, title, mainImage, description }) => {

    return (
        <Link to={`/${slug}`} className="flex flex-col rounded-lg shadow-lg overflow-hidden hover:shadow-xl hover:-translate-y-1 transition transform ease-in-out duration-150">
            <div className="flex-shrink-0">
                <img className="h-48 w-full object-cover"
                    srcSet={`${mainImage.asset.url}?w=400&q=75&auto=format, ${mainImage.asset.url}?w=600&q=75&auto=format 2x`}
                    src={`${mainImage.asset.url}?w=400&q=75&auto=format` || ''}
                    alt={mainImage.alt}
                    height="192px"
                    width="414px"
                />
            </div>
            <div className="flex-1 bg-white p-6 flex flex-col justify-between">
                <div className="flex-1">
                    <p className="text-sm font-medium text-primary">
                        <span>
                            Articulo
                        </span>
                    </p>
                    <div className="mt-2">
                        <p className="text-xl font-semibold text-gray-900">
                            {title}
                        </p>
                        <p className="mt-3 text-base text-gray-500">
                            <BlockContent
                                blocks={description}
                            />
                        </p>
                    </div>
                </div>
                <div className="mt-6 flex items-center">
                    <div className="text-base font-medium text-primary">Continuar leyendo <span aria-hidden="true">→</span> </div>
                </div>
            </div>
        </Link>
    )
}


export default PostListElement